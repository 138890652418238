import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Brands = props => {
  const { data = {}} = props

  return (
    <section className='eg-brands' >
      <div className='title'>{data.title}</div>
      <div className='description'>{data.subtitle.subtitle} </div>
      <div className='logos'>
        {
          data.logos.map((logo, i) => {
            return <img
              data-src={`${logo.file.url}?w=130`}
              key={ `brand-logo-${i}` }
              className='logo lazyload' alt='brand'
            />
          })
        }
      </div>
    </section>
  )
}

Brands.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default Brands
