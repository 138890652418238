import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import 'lazysizes'

import MainLayout from '../components/MainLayout'
import HomePage from '../components/Home'
import { EGAnalytics, analyticEvents } from '../services/analytics'
import { getCmsContentType } from '../services/util'

const scrollHandler = () => {
  if (!window.contentViewed) {
    if ((window.innerHeight + document.documentElement.scrollTop) >= document.body.scrollHeight) {
      EGAnalytics.track(analyticEvents.HOMEPAGE_CONTENT_VIEWED)
      window.contentViewed = true
    }
  }
}

export default (props) => {
  const homeV4data = _.get(props, 'data.allContentfulHomev4.nodes[0]')
  const data = getCmsContentType(props, process.env.GATSBY_CONTENTFUL_ID_HOME)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.contentViewed = false
      window.addEventListener('scroll', scrollHandler)
    }
    EGAnalytics.track(analyticEvents.HOMEPAGE_VIEWED)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <MainLayout data={ data }>
      <HomePage homeData= {homeV4data} />
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query Matadata {
    allContentfulHomeV3 {
      edges {
        node {
          title
          contentful_id
          createdAt
          hero {
            cta
          }
        }
      }
    }

    allContentfulHomev4 {
      nodes {
        hero {
          header
          subheader {
            subheader
          }
          cta
          heroImage {
            file {
              url
            }
          }
        }
        socialProof {
          title
          subtitle {
            subtitle
          }
          logos {
            file {
              url
            }
          }
        }
        features {
          title
          features {
            title
            description {
              description
            }
            image {
              file {
                url
              }
            }
          }
        }
        howItWorksStatsBottom {
          title
          subtitle {
            subtitle
          }
          steps {
            image {
              file {
                url
              }
            }
            title
            paragraph {
              paragraph
            }
          }
        }
        press {
          title
          articles {
            title
            hideContents
            urlSlug
            image {
              file {
                url
              }
            }
            source
            sourceLink
            sourceLogo {
              file {
                url
              }
            }
          }
        }
        bottomCta {
          title
          description {
            description
          }
          cta
          image {
            file {
              url
            }
          }
        }
      }
    }

  }
`
