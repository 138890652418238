import React from 'react'
import PropTypes from 'prop-types'
import Loadable from '@loadable/component'

import './styles.scss'
import Hero from './Hero'
import Brands from './Brands'
// const TopHowTo = Loadable(() => import(/* webpackPrefetch: true */ './TopHowTo'))
const Features = Loadable(() => import('./Features'))
// const HowItWork = Loadable(() => import('./HowItWork'))
const BottomHowTo = Loadable(() => import('./BottomHowTo'))
const ZsfPress = Loadable(() => import('./ZsfPress'))
const GetDemo = Loadable(() => import('./GetDemo'))


const HomePage = (props) => {
  const { homeData } = props

  return (
    <div className='eg-home-page'>
      <Hero data={ homeData.hero } />
      <Brands data={homeData.socialProof}/>
      {/* hide it <TopHowTo data={homeData.howItWorksStatsTop} /> */}
      {/* hide it<HowItWork data={ homeData.howItWorksSteps } /> */}
      <Features features={ homeData.features } />
      <BottomHowTo data={homeData.howItWorksStatsBottom} />
      <ZsfPress data={homeData.press}/>
      <GetDemo data={ homeData.bottomCta }/>
    </div>
  )
}

HomePage.propTypes = {
  homeData: PropTypes.object.isRequired,
}

export default HomePage
