import React from 'react'
import PropTypes from 'prop-types'

import { EGAnalytics, analyticEvents } from '../../../services/analytics'
import EmailBox from '../../EmailBox'

import './styles.scss'

const Hero = props => {
  const data = props.data

  const handleSubmit = () => {
    EGAnalytics.track(analyticEvents.CTA_INTERACTION, {
      page: 'Home Page',
      section: 'Hero',
      ctaLabel: data.cta
    })
  }

  return (
    <div className='eg-home-hero'>
      <div className='content'>
        <h1 className='title'>{data.header}</h1>
        <p className='description'>
          {data.subheader.subheader}
        </p>
        <EmailBox cta={data.cta} onSubmit={ handleSubmit } section='Hero' />
      </div>
      {/* <img src={data.heroImage.file.url}  alt="this slowpoke moves" className='hero-gif' loop='loop' preload="none" loading="lazy"/> */}
      <video  playsInline loop autoPlay muted className='hero-gif' >
        <source src={data.heroImage.file.url} type='video/mp4' />
      </video>
    </div>
  )
}

Hero.propTypes = {
  data: PropTypes.object
}

export default Hero
